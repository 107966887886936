
import { RequestPaging } from "@/utils/util";
import { Component, Vue } from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import PopoverInput from "@/components/popover-input.vue";
import Form from "@/components/form.vue";
import {
  apiModelMaterialList,
  apiModelMaterialAdd,
  apiModelMaterialEdit,
  apiModelMaterialDetail,
  apiModelMaterialDelete,
} from "@/api/model.ts";
@Component({
  components: {
    LsDialog,
    LsPagination,
    PopoverInput,
    Form,
  },
})
export default class ModelType extends Vue {
  pager: RequestPaging = new RequestPaging();
  searchObj: any = {
    zh_cn_name: "",
  };

  dataForm = {
    id: "",
    zh_cn_name: "",
    en_name: "",
    label: "",
    image: "",
    zh_cn_content: "",
    en_content: "",
  };
  formData = [
    {
      type: "input",
      label: "名称",
      key: "zh_cn_name",
      rules: [{ required: true, message: "请输入名称", trigger: "blur" }],
    },
    {
      type: "input",
      label: "英文名称",
      key: "en_name",
      rules: [{ required: true, message: "请输入英文名称", trigger: "blur" }],
    },
    {
      type: "input",
      label: "标识",
      key: "label",
      rules: [{ required: true, message: "请输入标识", trigger: "blur" }],
    },
    {
      type: "image",
      label: "主图",
      key: "image",
      rules: [{ required: true, message: "请选择图片", trigger: "change" }],
    },
    {
      type: "editor",
      label: "介绍",
      key: "zh_cn_content",
    },
    {
      type: "editor",
      label: "英文介绍",
      key: "en_content",
    },
  ];
  mode = "add";
  formVisible = false;

  handleAdd() {
    this.dataForm = {
      id: "",
      zh_cn_name: "",
      en_name: "",
      label: "",
      image: "",
      zh_cn_content: "",
      en_content: "",
    };
    this.mode = "add";
    this.formVisible = true;
  }

  handleEdit(row: any) {
    this.dataForm = {
      id: row.id,
      zh_cn_name: row.zh_cn_name,
      en_name: row.en_name,
      label: row.label,
      image: row.image,
      zh_cn_content: row.zh_cn_content,
      en_content: row.en_content,
    };
    this.mode = "edit";
    this.formVisible = true;
  }

  handleSubmit(value: object) {
    if (this.mode == "add") {
      apiModelMaterialAdd(value).then((res) => {
        this.getList();
        this.formVisible = false;
      });
    } else {
      apiModelMaterialEdit(value).then((res) => {
        this.getList();
        this.formVisible = false;
      });
    }
  }

  handleDelete(id: number) {
    apiModelMaterialDelete({ id: id }).then((res) => {
      this.getList();
    });
  }

  getList(): void {
    this.pager.request({
      callback: apiModelMaterialList,
      params: {
        ...this.searchObj,
      },
    });
  }

  created() {
    this.getList();
  }
}
